<template>
  <transition name="slide-fade">
    <div
      class="header-menu"
      :class="{ 'is-opened': isOpenMenu }"
      v-if="isOpenMenu"
    >
      <div class="container">
        <div class="header-menu__content">
          <div class="header-menu__left">
            <div ref="headerLogo" class="header-menu__logo">
              <button
                class="burger"
                @click="openMenu"
                :class="{ 'is-active': isOpenMenu }"
              >
                <span class="burger-line burger-top"></span>
                <span class="burger-line burger-middle"></span>
                <span class="burger-line burger-bottom"></span>
              </button>
              <locale-router-link to="" class="header-menu__logo-box">
                <img
                  v-if="$i18n.locale === 'ru'"
                  src="@/assets/img/icons/logo-footer.svg"
                  class="header-menu-logo-desktop"
                />
                <img
                  v-else
                  src="@/assets/img/icons/logo-footer-uz.svg"
                  alt="logo"
                  class="header-menu-logo-desktop"
                />
                <img
                  src="@/assets/img/icons/logo-mobile.svg"
                  alt="logo"
                  class="header-menu-logo-mobile"
                />

                <p class="header-menu__title-mobile">
                  {{ $t("WeFillLife") }}
                </p>
              </locale-router-link>
            </div>
            <div class="header-menu__list">
              <locale-router-link to="stocks" class="header-menu__link">
                {{ $t("stock") }}</locale-router-link
              >
              <locale-router-link to="catalog" class="header-menu__link">{{
                $t("discountCatalog")
              }}</locale-router-link>
              <locale-router-link to="magnumclub" class="header-menu__link">{{
                $t("loyaltyProgram")
              }}</locale-router-link>
              <locale-router-link to="news" class="header-menu__link">{{
                $t("companyNews")
              }}</locale-router-link>
            </div>
          </div>
          <div class="header-menu__right">
            <div class="header-menu__right-inner">
              <locale-router-link to="about" class="header-menu__link">{{
                $t("aboutCompany")
              }}</locale-router-link>
              <locale-router-link to="cooking" class="header-menu__link">
                {{ $t("cookingWithMagnum") }}
              </locale-router-link>
              <a
                v-if="$i18n.locale === 'ru'"
                href="https://magnum.jobster.uz/"
                class="header-menu__link"
                >{{ $t("workingMagnum") }}
              </a>
              <a
                v-else
                href="https://magnum.jobster.uz/uz"
                class="header-menu__link"
                >{{ $t("workingMagnum") }}
              </a>
              <locale-router-link to="contacts" class="header-menu__link">
                {{ $t("contacts") }}</locale-router-link
              >
            </div>
          </div>
          <div class="header-menu__settings">
            <div
              v-if="selectedCity"
              class="header-menu__address"
              @click="openSelectCity"
            >
              {{ selectedCity.attributes.name.substring(0, 3) }}
            </div>
            <lang-change />
          </div>
        </div>
        <div class="header-menu__box header-menu-bottom">
          <div class="header-menu-bottom__item">
            <div class="header-menu__phones">
              <span>
                {{ $t("support") }}
              </span>
              <a href="tel:998555000066">+998 55 500-00-66 </a>
            </div>
            <div class="header-menu__social-links">
              <span>
                {{ $t("weAreInSocialNetworks") }}
              </span>
              <div class="header-menu__links" v-if="social">
                <a
                  v-if="social.attributes.facebook_active"
                  class="fb"
                  :href="social.attributes.facebook_link"
                  target="_blank"
                ></a
                ><a
                  v-if="social.attributes.IG_active"
                  class="insta"
                  :href="social.attributes.IG"
                  target="_blank"
                ></a
                ><a
                  v-if="social.attributes.telegram_active"
                  class="telegram"
                  :href="social.attributes.telegram"
                  target="_blank"
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { getSocialLink } from "@/api/links";
import { mapState, mapActions } from "vuex";
import { actionTypes } from "@/store";
export default {
  name: "AppMenu",
  data() {
    return {
      social: null,
    };
  },
  components: {
    LangChange: () => import("@/components/language/LangChange.vue"),
  },
  computed: {
    ...mapState(["isOpenMenu"]),
    ...mapState("cityModule", ["selectedCity"]),
  },
  methods: {
    ...mapActions({
      closeMenu: actionTypes.changeIsOpenMenu,
      openMenu: actionTypes.changeIsOpenMenu,
      openSelectCity: actionTypes.changeIsOpenSelectCity,
    }),
  },
  watch: {
    "$route.path"() {
      if (this.isOpenMenu) {
        this.closeMenu();
      }
    },
  },
  created() {
    getSocialLink().then((social) => {
      this.social = social;
    });
  },
};
</script>

<style lang="scss" scoped></style>
